// CONTENT.JS
//
// This File contains the contents of the website!
// Changes in this file only take effect, after: npm run build
//
// The website content is specified as a javascript object literal below.
// The "content"-fields, below can be an object or an array of objects. Content objects can be nested.
//
// Hint: Use ` ` for multi-line strings. (This is what requires the build step - as it is not supported by all browsers)
//
// Supported Objects in content fields:
//
// // Simple HTML:
// 	{
//		type: "html"
//		value: "<p>some html<p>"
// 	}
// // Expandable: Allows to show/hide content by clicking on title
// 	{
// 		type: "expandable",
// 		title: "The Title",
// 		content: []
// 	}
// // Cards: Allows to display content in cards
// 	{
// 		type: "cards", // use "cards-light" for dark background
// 		cards:
// 		[
// 			{
// 				card: "1",
// 				content: []
// 			},
// 			{
// 				card: "2",
// 				content: []
// 			},
// 		]
// 	}
// // Table:
// 	{
// 		type: "table",
// 		widths: ['4em','auto','auto','auto'], // column widths
// 		// rowStyle: ["font-weight: bold;font-size: 1.2em", "font-weight: bold; background-color: #2B628D; color: white","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;"],
// 		// colStyle: ["font-weight: bold"],
// 		// cellStyle: "",

// 		// rowClass: [],
// 		// colClass: [],
// 		// cellClass: "",

// 		table: [
// 			["cell11","cell12","cell13","cell14"], // row 1
// 			["cell21","cell22","cell23","cell24"], // row 2
// 		]
// }
// Components:
// 	{
// 		type: "component:co-sponsors" / "component:sponsors" / "component:speakers" / "component:organizers"
// 	}

// Styles AND Classes:
// Except for Components (i.e., type: "component:..."). All content objects support fields "class" & "style" to add specific classes and styles to the contents.

// Timing:
// Every content object or property (simple properties can be made objects as {value: string})
// can have "from" and / or "till" properties that specify when they are to be displayed.
// If there are multiple choices depending on the date. Arrays of {value: x, from:y, till:z} are possible. The first match will be displayed.
// Time Format is: 2020-09-29T14:23:00 // all time must be specified in CEST = UTC+2
// Examples:
// header:{
//	 message: [{value: "Register Now", till:"2020-09-29T08:00"},{value: "Ongoing",from:"2020-09-29T08:00"}]
// }
//

// Icons: https://fontawesome.com/v5.3.1/icons?d=gallery

export var content = {
	copyrightText: "Copyright © 5G Lab Germany 2024 - ",
	menu: [
		{ text: "Home", target: "page-top" },
		{ text: "Venue", target: "venue" },
		{ text: "Partners", target: "partners" },
		{ text: "Speakers", target: "speakers" },
		{ text: "Registration", target: "registration" },
		{ text: "Agenda", target: "agenda" },
		{ text: "Exhibition", target: "exhibition" },
		{ text: "Committee", target: "committee" },
	],
	header: {
		title: "<span>IEEE Chips Summit<br>Dresden 2025</span>",
			//'<img alt="1st IEEE 6G Summit Dresden" src="img/heading_website.svg" class="tooltiptextself" style="height:2.5em; margin-bottom: 0em; max-width:100%">',
		dates: "May 14&ndash;15, 2025 in Dresden, Germany",
		message: "",
		buttons: [
			{
				text: "Follow Us",
				icon: "fab fa-twitter", // FA Icon
				target: "https://twitter.com/5g_lab",
			},
			{
				text: "LinkedIn",
				icon: "fab fa-linkedin",
				target: "https://www.linkedin.com/company/5g-lab-gmbh/",

			},
			// {
			// 	text: "Add to Calendar",
			// 	icon: "far fa-calendar-alt",
			// 	target: "6G-Summit-Dresden-2024.ics",
			// 	till: "2024-05-14T01:00:00"
			// },
			// {
			// 	text: "Agenda",
			// 	icon: "fa fa-file-pdf",
			// 	target: "Agenda.pdf",
			// },
	 		// {
			//  	text: "Impressions",
			//  	icon: "fa fa-image",
			//  	target: "https://5glab.de/impressions-of-the-9th-ieee-6g-summit-2024/",
			//  },
			// {
			// 	text: "Register Now",
			// 	icon: "fa fa-pen-nib",
			// 	target: "#registration",
			// 	till: "2023-05-09T18:00:00",
			// },

			// {
			// 	text: "Revisit 2024 Summit",
			// 	icon: "fa fa-redo",
			// 	target: "/dresden-2024"
			// },

			// {
			// 	text: "Register Now",
			// 	//icon: "play", // FA Icon
			// 	target: "registration",
			// 	till: "2020-10-08T23:30:00"
			// },
			// {
			// 	text: "Stay Tuned",
			// 	icon: "twitter", // FA Icon
			// 	target: "https://twitter.com/5g_lab"
			// }
		],
	},
	sections: [

		{
			name: "about",
			background: "light",
			content: [



				{
					type: "html",

					value: `
					<p>Join us for the first IEEE Chips Summit Dresden 2025 on May 14-15 at Messe Dresden, where industry leaders, academic pioneers, and tech enthusiasts will converge for a dynamic two-day event. Organized by IEEE, 5G Lab Germany, TU Dresden and Barkhausen Institut, this summit promises insightful talks from global professionals and innovative demonstrations from a diverse array of startups and academic institutions. Attendees will have the chance to engage in discussions about groundbreaking ideas, explore emerging trends, and discover new business opportunities that will shape the future of modern chips.​</p>

					`,
				},

				{
					type: "html",
					value: `
						<h3 style="margin-bottom: 1em; line-height: 1.5;" >Be part of this influential conference, where innovation meets expertise, and witness the evolution of the next generation of chips.</h3>
					`
				},
				{
					type: "html",
					value: `
					<cosponsors></cosponsors>
					`,
				},
			],
		},
		{
			name: "venue",
			title: "Venue",
			background: "venue",
			content: [
				{
					type: "html",
					style: "font-size: 1.2em",
					value: `

					<p><strong>Location:</strong> <a style="margin-left: 0.6em; display:inline-block" href="https://www.google.com/maps/place/Messering+6,+01067+Dresden/" target="_blank">Messe Dresden</a> </p>
					<p><strong>Accommodation:</strong> <a style="margin-left: 0.6em" href="https://www.hrs.de/list?location=%25765608&orderBy=Recommendations&startDateDay=13&startDateMonth=5&startDateYear=2025&endDateDay=15&endDateMonth=5&endDateYear=2025&lang=de&currency=EUR&adults=1&singleRooms=1&intcmp=btcweb" target="_blank">Book a Hotel</a></p>
					Feel free to use our booking links for the Maritim Hotel in
					<a href="https://reservations.travelclick.com/109832?groupID=4410313&adults=1&languageID=7">German</a> or <a href="https://reservations.travelclick.com/109832?groupID=4410313&adults=1">English</a>.
					`,
				},
				//Venue plan here
				// {
				// 	type: "html",
				// 	value: `
				// 	<div style="margin-top:2em; width: 100%; display: flex; justify-content: center; flex-direction: column; gap: 1em;">
				// 		<h2>Venue Plan</h2>
				// 		<a title="open picture separately" class="raw" href="img/map-venue.svg"><img alt="Venue Plan" src="img/map-venue.svg" style="width:900px; max-width:90%"></a>
				// 	</div>
				// 	`,
				// }
			],
		},
		{
			name: "partners",
			title: "Partners",
			content: [
				{
					type: "html",
					value: `



						 <sponsors json="data/sponsors.json" pictures="data/sponsors/" :exclude="['Exhibitors']"></sponsors>
						 <p>Interested in becoming a patron? Check our <a href="ChipsSummit_PatronageFlyer_2025.pdf">Patronage Brochure</a>.</p>
						 <p>To arrange your individual patronage package, please contact <a href="mailto:nicole.flechs@tu-dresden.de">Nicole Flechs</a>.</p>
						 `

				},
			],
		},
		{
			name: "speakers",
			title: "Speakers",
			background: "dark",
			content: [

				{
					type: "component:speakers",
				},
			],
		},

		{
			name: "registration",
			title: "Registration",
			content: [

				{
					type: "html",
					value: `
					<p>Coming 2025.</p>
					`,
				},
				//  {
				//  	type: "html",
				//  	till: "2024-05-13T18:00:00",
				//  	value: `
				//  	<p><p style="margin-top: -0em; font-size: 1.5em;">Registration is now available. Please register <a style="font-weight: bold" href="https://www.eventbrite.de/e/ieee-6g-summit-dresden-2024-tickets-838499754337" target="_blank">&gt;&gt;&nbsp;here&nbsp;&lt;&lt;</a>&nbsp;.</p></p>
				//  	`,
				//  },
				//  {
				//  	type: "html",
				//  	from: "2023-05-09T18:00:00",
				//  	value: `
				//  	<p><strong>Note:</strong> Online registration is not available anymore.</p>
				//  	`
				//  },

				//  {
				//  	type: "html",
				//  	value: `
				//  	<h2 style="margin-bottom: 1em;">Prices</h2>
				//  	<label class="check-label" style="margin-top: 0; font-size: 1.3em; cursor: pointer;"><input type="checkbox" v-model="l.includeVat">Include VAT in prices</label>
				//  	<div style="margin-bottom: -1em"></div>
				//  	`,
				//  },
				//  {
				//  	type: "table",
				//  	widths: ["12em", "auto", "auto", "auto"],
				//  	rowStyle: ["font-weight: bold; border-bottom: 1px solid gray; background-color: rgb(14, 83, 116); color: white;"],
				//  	colStyle: ["font-weight: bold;"],
				//  	cellStyle: "font-size: 1.2em;",

				//  	 		 rowClass: [],
				//  	 		 colClass: [],
				//  	 		 cellClass: "",
				//  	table: [
				//  		[``, "Standard", "Late", "Last Minute / On-Site"],
				//  		["Regular *", "{{getPrice(500)}} €", "{{getPrice(550)}} €", "{{getPrice(600)}} €"],
				//  		["IEEE Member *", "{{getPrice(450)}} €", "{{getPrice(500)}} €", "{{getPrice(550)}} €"],
				//  		["Student *", "{{getPrice(250)}} €", "{{getPrice(275)}} €", "{{getPrice(300)}} €"],
				//  		[
				//  			"Networking Only **",
				//  			"{{getPrice(100)}} €",
				//  			"{{getPrice(125)}} €",
				//  			"{{getPrice(150)}} €",
				//  		],
				//  	],
				//  },
				//  {
				//  	type: "html",
				//  	value: `
				//  	<div style="font-size: 0.9em">
				//  		<p style="margin-top: -1em">Prices <span v-show="!l.includeVat">excluding 19% VAT that apply additionally</span><span v-show="l.includeVat">including 19% VAT</span>.</p>
				//  		<p>* Includes participation in the conference at Messe Dresden on May 13 + 14, coffee break and lunch catering, and participation in the conference dinner on May 14.</p>
				//  		<p>** Includes participation in the conference dinner on May 14.</p>
				//  	</div>
				//  	`,
				//  },
				//  {
				//  	type: "html",
				//  	value: `
				//  	<h2 style="margin-bottom: 1em;">Registration Deadlines</h2>
				//  	<div style="display: inline-block;">
				//  	<table style="font-size: 2.2rem; line-height: 2">
				//  		<tr>
				//  			<td style="font-weight: bold; ">Standard</td>
				//  			<td style="width: 8em;">April 24, 2024</td>
				//  			<td>(00:00 CEST)</td>
				//  		</tr>
				//  		<tr>
				//  			<td style="font-weight: bold">Late</td>
				//  			<td>May 13, 2024</td>
				//  			<td>(12:00 CEST)</td>
				//  		</tr>
				//  		<tr>
				//  			<td style="font-weight: bold">Last&nbsp;Minute/ On-Site</td>
				//  			<td>   available on site </td>
				//  		</tr>
				//  	</table>
				//  	</div>
				//  	`
				//  }


			],
		},
		{
			name: "agenda",
			title: "Agenda",
			background: "dark",
			content: [
				{
					type: "html",
					value: `<p>To be announced.</p>`,
				}
				// {
				// 	type: "html",
				// 	// <p>IEEE 5G++ Summit Dresden has become a prominent annual fixture on the road to 6G. The well-known high-impact conference brings together industry leaders, innovators, and researchers from industry and academia to exchange ideas that will help to drive standards and rapid deployment of 5G++ technologies. The IEEE 5G++ Summit Dresden takes a holistic approach to 5G++ system design, ranging from silicon hardware, wireless interfaces, networks, edge clouds all the way up to Tactile Internet applications.</p>
				// 	value: `
				// 	<h2>Monday, May 13<sup>th</sup></h2>
				// 	<p> 5G/6G will be a revolution in the industry, it has been announced, but so far there is none. Probably there won't be one, but rather an evolution that needs to be driven by all stakeholders, and there are many, necessary for it to be a success. With this track, we want to stimulate discussions between verticals, academia, and the telecom industry to achieve a recognizable (r)evolution of 5G/6G in the shop floor of vertical industry.</br></p>

				// 	`,
				// },
				// {
				// 	type: "html",
				// 	// <p>IEEE 5G++ Summit Dresden has become a prominent annual fixture on the road to 6G. The well-known high-impact conference brings together industry leaders, innovators, and researchers from industry and academia to exchange ideas that will help to drive standards and rapid deployment of 5G++ technologies. The IEEE 5G++ Summit Dresden takes a holistic approach to 5G++ system design, ranging from silicon hardware, wireless interfaces, networks, edge clouds all the way up to Tactile Internet applications.</p>
				// 	value: `
				// 	<div style="width: 100%; display: flex; justify-content: center;">
				// 	<div style="border: 1px solid white; max-width: 95%; overflow-x: auto;">
				// 			<img alt="Agenda" src="AgendaMonday0429.svg" width="900px">
				// 		</div>
				// 		</div>
				// 	`,
				// },
				// {
				// 	type: "html",
				// 	// <p>IEEE 5G++ Summit Dresden has become a prominent annual fixture on the road to 6G. The well-known high-impact conference brings together industry leaders, innovators, and researchers from industry and academia to exchange ideas that will help to drive standards and rapid deployment of 5G++ technologies. The IEEE 5G++ Summit Dresden takes a holistic approach to 5G++ system design, ranging from silicon hardware, wireless interfaces, networks, edge clouds all the way up to Tactile Internet applications.</p>
				// 	value: `
				// 	<h2>Tuesday, May 14<sup>th</sup></h2>
				// 	`,
				// },
				// {
				// 	type: "html",
				// 	value: `
				// 	<div style="width: 100%; display: flex; justify-content: center;">
				// 	<div style="border: 1px solid white; max-width: 95%; overflow-x: auto;">
				// 			<img alt="Agenda" src="AgendaTuesday0508.svg" width="900px">
				// 		</div>
				// 	</div> `
				// 	//  <div style="width: 100%; display: flex; justify-content: center;">
				// 	//  	<div style="border: 1px solid white; max-width: 95%; overflow-x: auto;">
				// 	//  		<img alt="Agenda" src="Agenda_Wednesday.svg" width="800px">
				// 	//  	</div>
				// 	//  </div>
				// 	// `,
				// },
			 ],
		},
		{
			name: "exhibition",
			title: "Exhibition",
			// background: "dark",
			content: [
				// {
				// 	type: "html",
				// 	value: `<p>To be announced.</p>`,
				// }
				// {
				// 	type: "html",
				// 	value: `
				// 	<div style="width: 100%; display: flex; justify-content: center;">
				// 	<a class="raw" title="open picture separately" href="exhibition_plan.svg"><img alt="Exhibition Plan" src="exhibition_plan.svg" style="width:1000px; max-width: 95%;"></a>
				// 	</div>
				// 	<sponsors json="data/sponsors.json" pictures="data/sponsors/" :include="['Exhibitors']"></sponsors>
				// 	`
				// },
				{
					type: "html",
					value: `

					<!--div class="sponsors-grid">
						<exhibitor name="Barkhausen Institut" number="3a" >
							<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
						</exhibitor>
						<exhibitor name="Huawei" number="4a" >
							<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
						</exhibitor>
					</div-->
					<!--sponsors :headings="true" json="data/sponsors.json" pictures="data/sponsors/" :include="['Exhibitors']"></sponsors-->
					<p>Interested in becoming an exhibitor at our event? Check our <a href="ChipsSummit_PatronageFlyer_2025.pdf">Patronage Brochure</a>.</p>
					`
				},
				//<div style="width: 100%; display: flex; justify-content: center;"><div style="border: 1px solid white; max-width: 95%; overflow-x: auto;"><img alt="Exhibition Plan" src="exhibition_plan.svg" width="600px"></div></div>
				// <p>Interested in becoming an exhibitor at our event? Patronage Brochure will be published soon.</p>
			],
		},

		{
			name: "committee",
			title: "Committee",
			background: "dark",
			content: [
				{
					type: "component:organizers",
				},
			],
		},
	],
};
